import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import './Borrow.css';

const borrow: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Borrow</IonTitle>
          </IonToolbar>
        </IonHeader>
        <ExploreContainer name="Borrow page" item="Item to borrow"/>
      </IonContent>
    </IonPage>
  );
};

export default borrow;
