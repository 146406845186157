import { IonItem, IonLabel } from '@ionic/react';

import './ExploreContainer.css';

interface ContainerProps {
  name: string;
  item: string;
}

const ExploreContainer: React.FC<ContainerProps> = ({ name, item }) => {
  return (
    <div className="ion-padding">
      <h1>{name}</h1>
      <IonItem routerLink='/home'>
        <IonLabel>{item}</IonLabel>
      </IonItem>
      <IonItem routerLink='/home'>
        <IonLabel>{item}</IonLabel>
      </IonItem>
      <IonItem routerLink='/home'>
        <IonLabel>{item}</IonLabel>
      </IonItem>
      <IonItem routerLink='/home'>
        <IonLabel>{item}</IonLabel>
      </IonItem>
      <IonItem routerLink='/home'>
        <IonLabel>{item}</IonLabel>
      </IonItem>
      <IonItem routerLink='/home'>
        <IonLabel>{item}</IonLabel>
      </IonItem>
      <IonItem routerLink='/home'>
        <IonLabel>{item}</IonLabel>
      </IonItem>
      <IonItem routerLink='/home'>
        <IonLabel>{item}</IonLabel>
      </IonItem>
      <IonItem routerLink='/home'>
        <IonLabel>{item}</IonLabel>
      </IonItem>
    </div>
  );
};

export default ExploreContainer;
