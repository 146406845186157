import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonButton,
  IonContent,
  IonFooter,
  IonGrid,
  IonHeader,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonRow,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTitle,
  IonToolbar,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { home, paperPlane, ticket } from 'ionicons/icons';
import Home from './pages/Home';
import Borrow from './pages/Borrow';
import Lend from './pages/Lend';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { Amplify } from 'aws-amplify';

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import awsmobile from './aws-exports';
Amplify.configure(awsmobile);

setupIonicReact();

const App: React.FC = () => {
  return (
      <Authenticator>
        {({ signOut }) => (
          <IonApp>
            <IonHeader>
              <IonToolbar>
                <IonGrid>
                  <IonRow class="ion-justify-content-end">
                    <IonButton onClick={signOut} color="danger">Sign Out</IonButton>
                  </IonRow>
                </IonGrid>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonReactRouter>
                <IonTabs>
                  <IonRouterOutlet>
                    <Route exact path="/home">
                      <Home />
                    </Route>
                    <Route exact path="/borrow">
                      <Borrow />
                    </Route>
                    <Route path="/lend">
                      <Lend />
                    </Route>
                    <Route exact path="/">
                      <Redirect to="/home" />
                    </Route>
                  </IonRouterOutlet>
                  <IonTabBar slot="bottom">
                    <IonTabButton tab="home" href="/home">
                      <IonIcon icon={home} />
                      <IonLabel>Home</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab="borrow" href="/borrow">
                      <IonIcon icon={ticket} />
                      <IonLabel>Borrow</IonLabel>
                    </IonTabButton>
                    <IonTabButton tab="lend" href="/lend">
                      <IonIcon icon={paperPlane} />
                      <IonLabel>Lend</IonLabel>
                    </IonTabButton>
                  </IonTabBar>
                </IonTabs>
              </IonReactRouter>
            </IonContent>
            <IonFooter></IonFooter>
          </IonApp>
        )}
      </Authenticator>
  );
}

export default App;