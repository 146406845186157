import { IonCard, IonCardHeader, IonCardTitle, IonCol, IonContent, IonPage, IonRow } from '@ionic/react';
import './Home.css';

const home: React.FC = () => {
  return (
    <IonPage>
      <IonContent className='homeContainer ion-padding'>
        <IonRow>
          <IonCol>
            <IonCard routerLink='/borrow' color="secondary">
              <IonCardHeader>
                <IonCardTitle>Borrow</IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonCol>
          <IonCol>
            <IonCard routerLink='/lend' color="tertiary">
              <IonCardHeader>
                <IonCardTitle>Lend</IonCardTitle>
              </IonCardHeader>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonContent>
    </IonPage>
  );
};

export default home;
